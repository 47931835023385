import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    marketTypes: null,
    allDevices: null,
    devices: null,
    total: 0,
    filter: {
        page: 1,
    },
};

const updateMarketTypes = (state, action) => {
    return updateState(
        state,
        {
            marketTypes: action.marketTypes,
        }
    );
};

const updateAllDevices = (state, action) => {
    return updateState(
        state,
        {
            allDevices: action.allDevices,
        }
    );
};

const updateDevices = (state, action) => {
    return updateState(
        state,
        {
            devices: action.devices,
            total: action.total,
        }
    );
};

const updateFilter = (state, action) => {
    return updateState(
        state,
        {
            filter: action.filter,
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_MARKET_TYPES: return updateMarketTypes(state, action);
    case actionTypes.UPDATE_ALL_DEVICES: return updateAllDevices(state, action);
    case actionTypes.UPDATE_DEVICES: return updateDevices(state, action);
    case actionTypes.UPDATE_DEVICE_FILTER: return updateFilter(state, action);
    default: return state;
    }
};

export default reducer;
