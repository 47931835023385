export const UPDATE_SIDER_COLLAPSED = "UPDATE_SIDER_COLLAPSED";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";

export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_BANK_ACCOUNTS = "UPDATE_BANK_ACCOUNTS";
export const UPDATE_BITCOIN_ACCOUNTS = "UPDATE_BITCOIN_ACCOUNTS";
export const UPDATE_LIGHTNING_ADDRESSES = "UPDATE_LIGHTNING_ADDRESSES";

export const UPDATE_WALLET = "UPDATE_WALLET";

export const UPDATE_INVOICES = "UPDATE_INVOICES";
export const UPDATE_INVOICE_FILTER = "UPDATE_INVOICE_FILTER";

export const UPDATE_TRANSACTIONS = "UPDATE_TRANSACTIONS";
export const UPDATE_TRANSACTION_FILTER = "UPDATE_TRANSACTION_FILTER";

export const UPDATE_WITHDRAWALS = "UPDATE_WITHDRAWALS";
export const UPDATE_WITHDRAWAL_FILTER = "UPDATE_WITHDRAWAL_FILTER";

export const UPDATE_SHIFTS = "UPDATE_SHIFTS";
export const UPDATE_SHIFT_FILTER = "UPDATE_SHIFT_FILTER";

export const UPDATE_ALL_COMPANIES = "UPDATE_ALL_COMPANIES";
export const UPDATE_COMPANIES = "UPDATE_COMPANIES";
export const UPDATE_COMPANY_FILTER = "UPDATE_COMPANY_FILTER";

export const UPDATE_ALL_USERS = "UPDATE_ALL_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_USER_FILTER = "UPDATE_USER_FILTER";

export const UPDATE_MARKET_TYPES = "UPDATE_MARKET_TYPES";
export const UPDATE_ALL_DEVICES = "UPDATE_ALL_DEVICES";
export const UPDATE_DEVICES = "UPDATE_DEVICES";
export const UPDATE_DEVICE_FILTER = "UPDATE_DEVICE_FILTER";
