import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    invoices: null,
    total: 0,
    filter: {
        page: 1,
    },
};

const updateInvoices = (state, action) => {
    return updateState(
        state,
        {
            invoices: action.invoices,
            total: action.total,
        }
    );
};

const updateFilter = (state, action) => {
    return updateState(
        state,
        {
            filter: action.filter,
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_INVOICES: return updateInvoices(state, action);
    case actionTypes.UPDATE_INVOICE_FILTER: return updateFilter(state, action);
    default: return state;
    }
};

export default reducer;
