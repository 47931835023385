import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    wallet: null,
};

const updateWallet = (state, action) => {
    return updateState(
        state,
        {
            wallet: action.wallet,
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_WALLET: return updateWallet(state, action);
    default: return state;
    }
};

export default reducer;
