export const TRANSLATIONS_TH = {
    // COMMON
    fieldRequired: "ช่องนี้จำเป็น",
    fieldInvalid: "ช่องนี้ไม่ถูกต้อง",
    fieldMaxLimit: "ต้องมีอักขระน้อยกว่า {{limit}} ตัว",
    fieldMaxValueLimit: "ค่าควรน้อยกว่า {{limit}}",
    fieldMinValueLimit: "ค่าควรมากกว่า {{limit}}",
    fieldEmailDuplicated: "อีเมลซ้ำกัน",
    fieldConfirmPasswordCorrectly: "ยืนยันรหัสผ่านให้ถูกต้อง",

    success: "ความสำเร็จ",
    warning: "คำเตือน",
    error: "ข้อผิดพลาด",

    noPermission: "คุณไม่มีสิทธิ์!",
    URLNotFound: "ไม่พบ URL",
    badRequest: "คำขอไม่ถูกต้อง",
    apiError: "ข้อผิดพลาดของ API",

    // HEADER
    myWallet: "กระเป๋าเงินของฉัน",
    myProfile: "ประวัติของฉัน",
    withdrawalAccounts: "บัญชีถอนเงิน",
    signOut: "ออกจากระบบ",

    // AUTH LAYOUT
    allRightReserved: "สงวนลิขสิทธิ์.",

    // LOGIN
    email: "อีเมล",
    password: "รหัสผ่าน",
    keepMeSignedIn: "ให้ฉันลงชื่อเข้าใช้",
    signIn: "เข้าสู่ระบบ",
    forgetPassword: "ลืมรหัสผ่าน",
    stillHaveNoAccount: "ยังไม่มีบัญชี?",
    signUp: "ลงชื่อ",
    getCodeFromYourEmail: "รับรหัสจากอีเมลของคุณ",
    getCodeFromYourAuthenticatorApp: "รับรหัสจากแอปยืนยันตัวตนของคุณ",
    enterCodeFromYourEmail: "ใส่รหัสจากอีเมลของคุณ",
    accountSuspendedError:
        "บัญชีนี้ถูกระงับ โปรดติดต่อฝ่ายสนับสนุนเพื่อ <a href='mailto:{{email}}' target='_blank'>{{email}}</a>",
    notAuthenticated: "คุณไม่ได้รับการรับรองความถูกต้อง โปรดเข้าสู่ระบบอีกครั้ง!",

    // REGISTER
    confirmPassword: "ยืนยันรหัสผ่าน",
    fullname: "ชื่อเต็ม",
    alreadyHaveAnAccount: "มีบัญชีอยู่แล้ว?",
    passwordRule: "ต้องมีอักขระ 8 ตัวและอักขระพิเศษอย่างน้อย 1 ตัว(!@#$&*)",

    // FORGET PASSWORD
    code: "รหัส",
    sendCode: "ส่งรหัส",
    resetPassword: "รีเซ็ตรหัสผ่าน",
    resendCode: "ส่งรหัสอีกครั้ง",
    backToSignIn: "กลับไปที่ลงชื่อเข้าใช้",
    verificationCodeSentSuccessfully: "ส่งรหัสยืนยันเรียบร้อยแล้ว",

    // VERIFY ACCOUNT
    verify: "ตรวจสอบ",
    verify_email: "ตรวจสอบอีเมล์",
    verify_phone: "ยืนยันโทรศัพท์",
    verifyDescriptionEmail:
        "เพื่อตรวจสอบความถูกต้องของบัญชีของคุณ เราได้ส่งรหัสยืนยันไปที่ <b class='color-blue'>{{email}}</b>",
    verifyDescriptionPhone:
        "เพื่อตรวจสอบความถูกต้องของบัญชีของคุณ เราได้ส่งรหัสยืนยันที่สองไปที่ <b class='color-blue'>+{{phone}}</b>",
    verifySucceeded: "บัญชีของคุณได้รับการยืนยันเรียบร้อยแล้ว",

    // SIDER
    dashboard: "แผงควบคุม",
    transactions: "ธุรกรรม",
    withdrawals: "การถอนเงิน",
    shift: "กะ",
    companies: "บริษัท",
    users: "ผู้ใช้",
    pos: "POS",

    // DASHBOARD
    total: "ทั้งหมด",
    wallet: "กระเป๋าสตางค์",
    incoming: "ขาเข้า",
    withdrawal: "การถอนเงิน",
    consumption: "การบริโภค",
    monthly: "รายเดือน",
    daily: "รายวัน",

    // INVOICES
    invoices: "ใบแจ้งหนี้",
    newInvoice: "ใบแจ้งหนี้ใหม่",
    expireAt: "หมดอายุที่",
    localCurrency: "สกุลเงินท้องถิ่น",
    amountReceived: "จำนวนเงินที่ได้รับ",
    paidAt: "ชำระที่",
    copyInvoiceUrl: "คัดลอก URL ใบแจ้งหนี้",
    refresh: "รีเฟรช",
    noInvoice: "ไม่มีใบแจ้งหนี้",
    payLightningInvoice: "ชำระใบแจ้งหนี้ Lightning",
    pay: "จ่าย",
    createInvoice: "สร้างใบแจ้งหนี้",
    invoiceDetail: "รายละเอียดใบแจ้งหนี้",
    invoiceIsExpired: "ใบแจ้งหนี้หมดอายุ",
    invoiceIsPaid: "ชำระใบแจ้งหนี้แล้ว",
    awaitingPayment: "รอการชำระเงิน",
    close: "ปิด",
    copy: "สำเนา",

    // TRANSACTIONS
    searchByDescription: "ค้นหาตามคำอธิบาย",
    selectPOSDevice: "เลือกอุปกรณ์ POS",
    all: "ทั้งหมด",
    startDate: "วันที่เริ่มต้น",
    finishDate: "วันที่เสร็จสิ้น",
    date: "วันที่",
    company: "บริษัท",
    device: "อุปกรณ์",
    description: "คำอธิบาย",
    amount: "จำนวน",
    tip: "เคล็ดลับ",
    currency: "สกุลเงิน",
    commission: "คณะกรรมการ",
    noTransaction: "ไม่มีการทำธุรกรรม",

    // WITHDRAWALS
    withdrawalToBitcoin: "การถอนเงินเป็น Bitcoin",
    withdrawalToLightningAddress: "การถอนเงินไปยังที่อยู่ Lightning",
    withdrawalWithLightningInvoice: "การถอนเงินด้วยใบแจ้งหนี้ Lightning",
    lightningAddress: "ที่อยู่ Lightning",
    selectLightningAddress: "เลือกที่อยู่ Lightning",
    withdrawalAmountLimitTip:
        "เราขอเตือนคุณว่าเพื่อความปลอดภัยของคุณ การถอนเงินครั้งแรกในบัญชีใหม่ต้องไม่เกิน {{firstAmount}} SATs การถอนเงินครั้งที่สองในบัญชีใหม่นี้สามารถทำได้หลังจากผ่านไป 24 ชั่วโมง และสำหรับจำนวน SAT สูงสุดต่อวันที่ {{dailyAmount}} SAT",
    lightningWithdrawalInvoiceTip:
        "คำขอถอนเงินนี้จะได้รับการตรวจสอบและอนุมัติโดยผู้ดูแลระบบ ซึ่งจะใช้เวลาสักครู่ ดังนั้น โปรดตั้งค่าการหมดอายุของใบแจ้งหนี้ให้เพียงพอเมื่อคุณสร้างใบแจ้งหนี้",
    request: "ขอ",
    toBankAccount: "เข้าบัญชีธนาคาร",
    toBitcoinAccount: "ไปยังบัญชีบิตคอยน์",
    requestWithdrawal: "ขอถอนเงิน",
    account: "บัญชี",
    status: "สถานะ",
    manager: "ผู้จัดการ",
    bank: "ธนาคาร",
    bitcoin: "บิทคอยน์",
    noWithdrawal: "ไม่มีการถอน",
    requestWithdrawalToBank: "ขอถอนเงินไปที่ธนาคาร",
    bankAccount: "บัญชีธนาคาร",
    selectBankAccount: "เลือกบัญชีธนาคาร",
    shortDescriptionAboutWithdrawal: "คำอธิบายสั้น ๆ เกี่ยวกับการถอนเงิน",
    cancel: "ยกเลิก",
    create: "สร้าง",
    requestWithdrawalToBitcoin: "ขอถอนเงินเป็น bitcoin",
    bitcoinAccount: "บัญชีบิตคอยน์",
    selectBitcoinAccount: "เลือกบัญชี bitcoin",
    balanceNotEnoughError: "ยอดคงเหลือในกระเป๋าเงินของคุณไม่เพียงพอสำหรับการชำระเงิน",
    withdrawalToLightningAccount: "ถอนไปยังบัญชีฟ้าผ่า",
    invoiceUrl: "URL ใบแจ้งหนี้",
    expiry: "หมดอายุ",
    seconds: "วินาที",
    proceedWithdrawal: "ดำเนินการถอนเงิน",
    withdrawalStatus_initiated: "ริเริ่ม",
    withdrawalStatus_in_review: "อยู่ระหว่างการตรวจสอบ",
    withdrawalStatus_in_process: "อยู่ในขั้นตอน",
    withdrawalStatus_finished: "ที่เสร็จเรียบร้อย",
    withdrawalStatus_rejected: "ถูกปฏิเสธ",
    withdrawalStatusDescription_initiated: "มีการสร้างคำขอถอนเงิน รอการตรวจสอบจากผู้จัดการ",
    withdrawalStatusDescription_in_review: "คำขอถอนเงินอยู่ภายใต้การตรวจสอบโดยผู้จัดการ",
    withdrawalStatusDescription_in_process: "กำลังดำเนินการขอถอนเงิน",
    withdrawalStatusDescription_finished: "กระบวนการถอนเงินเสร็จสิ้น",
    withdrawalStatusDescription_rejected: "คำขอถอนเงินถูกปฏิเสธ",

    // SHIFT
    name: "ชื่อ",
    startedAt: "เริ่มต้นที่",
    finishedAt: "จบที่",
    deviceParingCode: "รหัสการจับคู่อุปกรณ์",
    noShift: "ไม่มีการเปลี่ยนแปลง",

    // COMPANIES
    confirmDeleteCompany: "คุณแน่ใจหรือไม่ที่จะลบบริษัทนี้",
    delete: "ลบ",
    companyName: "ชื่อ บริษัท",
    createdAt: "สร้างเมื่อ",
    edit: "แก้ไข",
    noCompanies: "ไม่มีบริษัท",
    updateCompany: "อัพเดทบริษัท",
    createCompany: "สร้าง บริษัท",
    saveChanges: "บันทึกการเปลี่ยนแปลง",

    // USERS
    confirmDeleteUser: "คุณแน่ใจหรือที่จะลบผู้ใช้รายนี้?",
    noUsers: "ไม่มีผู้ใช้",
    updateUser: "อัปเดตผู้ใช้",
    createUser: "สร้างผู้ใช้",
    noCompanyAssigned: "ไม่มีบริษัทที่ได้รับมอบหมาย",
    reset: "รีเซ็ต",
    suspended: "ถูกระงับ",
    yes: "ใช่",
    assignedToNumberOfCompanies: "มอบหมายให้กับบริษัท {{number}} แห่ง",
    selectCompanies: "เลือกบริษัท",

    // DEVICES
    confirmDeleteDevice: "คุณแน่ใจหรือว่าต้องการลบอุปกรณ์นี้",
    POSDevices: "อุปกรณ์ POS",
    paringCode: "รหัสการจับคู่",
    user: "ผู้ใช้",
    printPurchase: "ซื้อพิมพ์",
    receiveTip: "รับทิป",
    noPOSDevices: "ไม่มีอุปกรณ์ POS",
    updateDevice: "อัปเดตอุปกรณ์",
    createDevice: "สร้างอุปกรณ์",
    noUserAssigned: "ไม่มีการกำหนดผู้ใช้",
    marketType: "ประเภทตลาด",

    // PROFILE
    generalInformation: "ข้อมูลทั่วไป",
    birthday: "วันเกิด",
    phone: "โทรศัพท์",
    country: "ประเทศ",
    avatar: "รูปโปรไฟล์",
    selectFile: "เลือกไฟล์",
    save: "บันทึก",
    security: "ความปลอดภัย",
    "2fa": "การรับรองความถูกต้องด้วยสองปัจจัย",
    "2faDescription":
        "การตรวจสอบสิทธิ์แบบสองปัจจัย (2FA) เป็นวิธีรักษาความปลอดภัยที่ใช้เพื่อปกป้องบัญชี www.rodpay.com ของคุณ วัตถุประสงค์หลักของการตรวจสอบสิทธิ์แบบสองปัจจัยคือการเพิ่มระดับการรักษาความปลอดภัยเพิ่มเติมนอกเหนือจากรหัสผ่านแบบเดิม การใช้อีเมลเป็นปัจจัยที่สองในการตรวจสอบสิทธิ์ แม้ว่ามีคนจัดการเพื่อรับรหัสผ่านของคุณ พวกเขาจะไม่สามารถเข้าถึงบัญชีของคุณได้หากไม่มีการเข้าถึงอีเมลของคุณเพื่อรับรหัส",
    allow2fa: "เปิดใช้งานการรับรองความถูกต้องด้วยสองปัจจัย (2FA)",
    setup2FASuccess: "การตั้งค่าการตรวจสอบสิทธิ์แบบสองปัจจัย (2FA) สำเร็จแล้ว",
    confirmDisable2fa: "คุณแน่ใจว่าจะปิดการใช้งานการตรวจสอบสิทธิ์แบบสองปัจจัย (2FA) หรือไม่?",
    confirm: "ยืนยัน",
    disable2fa: "ปิดการใช้งานการรับรองความถูกต้องด้วยสองปัจจัย (2FA)",
    inputCode: "รหัสอินพุต",
    "2faSetupGuide":
        "โปรดสแกนโค้ด QR ด้านล่างโดยใช้แอปตรวจสอบความถูกต้องของคุณ เช่น Google Authenticator และรหัสอินพุต",
    customerInformation: "ข้อมูลลูกค้า",
    nationalID: "บัตรประจำตัวประชาชน",
    companyDUNS: "บริษัท ดันส์",
    selectFileByClickOrDragFileHereToUpload: "เลือกไฟล์โดยคลิกหรือลากไฟล์มาที่นี่เพื่ออัปโหลด",
    upload: "ที่อัพโหลด",
    nationalIDNeededDescription: "หากต้องการถอน SAT มากกว่า 1,000,000 SAT คุณต้องยืนยันบัตรประจำตัวประชาชนของคุณ",
    companyDUNSNeededDescription: "หากต้องการถอน SAT มากกว่า 10,000,000 SAT คุณต้องยืนยันบริษัท DUNS ของคุณ",
    nationalIDIsUploaded: "อัพโหลดบัตรประจำตัวประชาชนแล้ว",
    companyDUNSIsUploaded: "อัปโหลดบริษัท DUNS แล้ว",
    inReview: "ในการตรวจสอบ",
    approved: "ที่ได้รับการอนุมัติ",
    rejected: "ถูกปฏิเสธ",

    // INTEGRATION
    integration: "บูรณาการ",
    apiKey: "คีย์ API",
    apiKeyDescription:
        "หากต้องการรวม RodPay เข้ากับร้านค้า WooCommerce ของคุณเอง คุณจะต้องสร้างคีย์ API และกำหนดการตั้งค่าเกตเวย์การชำระเงินโดยใช้คีย์ API",
    generateApiKey: "สร้างคีย์ API",
    apiKeyGeneratedTip: "คีย์ API นี้มองเห็นได้เพียงครั้งเดียว โปรดบันทึกไว้ในที่ปลอดภัย",
    copyToClipboard: "คัดลอกไปยังคลิปบอร์ด",
    apiKeySaved: "บันทึกคีย์ API แล้ว",
    copiedToClipboard: "คัดลอกคีย์ API ไปยังคลิปบอร์ดแล้ว",
    apiKeySavedTip: "คุณได้บันทึกคีย์ API ของคุณแล้ว คุณสามารถสร้างคีย์ใหม่หรือเพิกถอนได้",
    generateNewApiKey: "สร้างคีย์ API ใหม่",
    revokeApiKey: "เพิกถอนคีย์ API",
    confirmRefreshApiKey: "คุณแน่ใจหรือว่าจะรีเฟรชคีย์ API",
    confirmRevokeApiKey: "คุณแน่ใจหรือว่าจะเพิกถอนคีย์ API",
    revoke: "ถอน",

    // USER LOG
    actionLogs: "บันทึกการดำเนินการ",
    action: "การกระทำ",
    model: "แบบอย่าง",
    detail: "รายละเอียด",
    user_action_create: "สร้าง",
    user_action_update: "อัปเดต",
    user_action_delete: "ลบ",
    user_action_soft_delete: "ลบ",
    user_action_restore: "คืนค่า",
    user_action_reset_password: "รีเซ็ตรหัสผ่าน",
    user_action_tfa_enable: "เปิดใช้งาน 2FA",
    user_action_tfa_disable: "ปิดการใช้งาน 2FA",
    user_action_update_profile: "อัพเดตโปรไฟล์",
    user_action_invoice_paid: "ชำระเงินตามใบแจ้งหนี้แล้ว",
    user_action_invoice_expired: "ใบแจ้งหนี้หมดอายุแล้ว",
    user_action_withdrawal_in_review: "ตรวจสอบการถอนเงิน",
    user_action_withdrawal_in_process: "ดำเนินการถอนเงิน",
    user_action_withdrawal_finished: "ถอนเงินให้เสร็จสิ้น",
    user_action_withdrawal_rejected: "ปฏิเสธการถอนเงิน",
    user_action_api_key_generated: "สร้างคีย์ API แล้ว",
    user_action_api_key_revoked: "เพิกถอนคีย์ API แล้ว",

    // WITHDRAWAL ACCOUNTS
    bankAccounts: "บัญชีธนาคาร",
    bitcoinAccounts: "บัญชีบิตคอยน์",
    confirmDeleteBankAccount: "คุณแน่ใจหรือไม่ว่าจะลบบัญชีธนาคารนี้",
    noBankAccountAdded: "ไม่มีการเพิ่มบัญชีธนาคาร",
    confirmDeleteBitcoinAccount: "คุณแน่ใจหรือที่จะลบบัญชี bitcoin นี้?",
    noBitcoinAccountAdded: "ไม่มีการเพิ่มบัญชี bitcoin",
    updateBankAccountInfo: "อัปเดตข้อมูลบัญชีธนาคาร",
    addNewBankAccount: "เพิ่มบัญชีธนาคารใหม่",
    nickname: "ชื่อเล่น",
    bankName: "ชื่อธนาคาร",
    swiftCode: "รหัสสวิฟท์",
    accountNumber: "หมายเลขบัญชี",
    accountName: "ชื่อบัญชี",
    address: "ที่อยู่",
    zipCode: "รหัสไปรษณีย์",
    updateBitcoinAccountInfo: "อัปเดตข้อมูลบัญชี bitcoin",
    addNewBitcoinAccount: "เพิ่มบัญชี bitcoin ใหม่",
    lightningAddresses: "ที่อยู่ Lightning",
    addNewLightningAddress: "เพิ่มที่อยู่ Lightning ใหม่",
    confirmDeleteLightningAddress: "คุณแน่ใจหรือไม่ว่าจะลบที่อยู่ Lightning นี้?",
    noLightningAddressAdded: "ไม่มีการเพิ่มที่อยู่ Lightning",

    // WITHDRAWAL
    firstWithdrawalAmountLimitExceeded:
        "เกินขีดจำกัดจำนวนเงินที่ถอนออก จำนวนเงินที่ถอนออกครั้งแรกควรน้อยกว่า 5,000 SATs",
    secondWithdrawalTimeLimitExceeded:
        "เกินขีดจำกัดเวลาการถอน การถอนครั้งที่สองสามารถทำได้หลังจาก 24 ชั่วโมงนับจากการถอนครั้งแรก",
    oneDayWithdrawalAmountLimitExceeded: "เกินขีดจำกัดจำนวนเงินที่ถอนออก จำนวนถอนรายวันควรน้อยกว่า 1,000,000 SATs",
    withdrawalKYCNationalIDRequired:
        "จำนวนเงินที่ถอนทั้งหมดของคุณเกินขีดจำกัด (1,000,000 SATs) โปรดยืนยันบัตรประจำตัวประชาชนของคุณเพื่อดำเนินการต่อ",
    withdrawalKYCCompanyDUNSRequired:
        "จำนวนเงินที่ถอนทั้งหมดของคุณเกินขีดจำกัด (10,000,000 SAT) โปรดยืนยันบริษัท DUNS ของคุณเพื่อดำเนินการต่อ",
    lightningInvoice: "ใบแจ้งหนี้ Lightning",

    // PAYMENT PAGE
    waitingForPayment: "กำลังรอการชำระเงิน...",
    invoiceQRCodeTip: "สแกนรหัส QR เพื่อชำระใบแจ้งหนี้หรือคลิกเพื่อคัดลอก URL ใบแจ้งหนี้",
    invoiceURLCopied: "คัดลอก URL ใบแจ้งหนี้ไปยังคลิปบอร์ดแล้ว",
    invoiceExpired: "ใบแจ้งหนี้หมดอายุ!",
    paymentSucceeded: "ชำระเงินสำเร็จ!",
    redirecting: "กำลังเปลี่ยนเส้นทาง...",
};
