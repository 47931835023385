import { getValidPathname } from "./helpers";

export const secondaryUserRestrictedRoutes = [
    "/wallet",
    "/companies",
    "/users",
    "/pos",
];

export const allRoutes = [
    "/wallet",
    "/transactions",
    "/companies",
    "/users",
    "/pos",
    "/profile",
];

export const restrictedRoutes = (role) => {
    switch(role) {
    case 1: return [];
    case 2: return [];
    case 3: return [];
    case 4: return secondaryUserRestrictedRoutes;
    default: return allRoutes;
    }
};

export const checkRoute = (role, pathname) => {
    return !restrictedRoutes(role).includes(getValidPathname(pathname));
};
