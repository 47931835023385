import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_ES } from "./es/translations";
import { TRANSLATIONS_PT } from "./pt/translations";
import { TRANSLATIONS_FR } from "./fr/translations";
import { TRANSLATIONS_IT } from "./it/translations";
import { TRANSLATIONS_RU } from "./ru/translations";
import { TRANSLATIONS_TH } from "./th/translations";
import { TRANSLATIONS_VI } from "./vi/translations";
import { TRANSLATIONS_FI } from "./fi/translations";
import { TRANSLATIONS_ZH } from "./zh/translations";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN,
            },
            es: {
                translation: TRANSLATIONS_ES,
            },
            pt: {
                translation: TRANSLATIONS_PT,
            },
            fr: {
                translation: TRANSLATIONS_FR,
            },
            it: {
                translation: TRANSLATIONS_IT,
            },
            ru: {
                translation: TRANSLATIONS_RU,
            },
            th: {
                translation: TRANSLATIONS_TH,
            },
            vi: {
                translation: TRANSLATIONS_VI,
            },
            fi: {
                translation: TRANSLATIONS_FI,
            },
            zh: {
                translation: TRANSLATIONS_ZH,
            },
        }
    });

export default i18n;
