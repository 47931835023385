import * as actionTypes from "../actionTypes";

import { updateState } from "../utils";

const initialState = {
    transactions: null,
    total: 0,
    totalAmount: 0,
    totalTipAmount: 0,
    filter: {
        page: 1,
        searchKey: "",
        startDate: null,
        finishDate: null,
        device: null,
    },
};

const updateTransactions = (state, action) => {
    return updateState(
        state,
        {
            transactions: action.transactions,
            total: action.total,
            totalAmount: action.totalAmount,
            totalTipAmount: action.totalTipAmount,
        }
    );
};

const updateFilter = (state, action) => {
    return updateState(
        state,
        {
            filter: action.filter,
        }
    );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_TRANSACTIONS: return updateTransactions(state, action);
    case actionTypes.UPDATE_TRANSACTION_FILTER: return updateFilter(state, action);
    default: return state;
    }
};

export default reducer;
