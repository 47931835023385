import i18n from "../translations/i18n";

export const checkValidEmail = (email) => {
    const regex = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
};

export const checkValidPassword = (password) => {
    return RegExp(/^(?=.*[a-zA-Z0-9])(?=.*[!@#$&*]).{8,}$/).test(password);
};

export const getErrorMessage = (e) => {
    if (e.message === "Network Error") {
        return e.message;
    } else {
        if (e.response) {
            if (e.response.status === 401) {
                return i18n.t("notAuthenticated");
            } else if (e.response.status === 403) {
                return i18n.t("noPermission");
            } else if (e.response.status === 404) {
                return i18n.t("URLNotFound");
            } else if (e.response.status === 405) {
                return i18n.t("badRequest");
            } else if (e.response.status === 471) {
                return i18n.t("firstWithdrawalAmountLimitExceeded");
            } else if (e.response.status === 472) {
                return i18n.t("secondWithdrawalTimeLimitExceeded");
            } else if (e.response.status === 473) {
                return i18n.t("oneDayWithdrawalAmountLimitExceeded");
            } else if (e.response.status === 500) {
                return i18n.t("apiError");
            } else {
                if (e.response?.data?.detail) {
                    return e.response.data.detail;
                } else {
                    return i18n.t("badRequest");
                }
            }
        } else {
            return i18n.t("apiError");
        }
    }
};

export const getValidPathname = (pathname) => {
    return pathname.endsWith("/") && pathname.length > 1 ? pathname.substring(0, pathname.length - 1) : pathname;
};

export const fileToBase64Handler = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
    });
};

export const getSecuredEmail = (email) => {
    if (!email) return "";
    const arr = email.split("@");
    return `${arr[0][0]}********@${arr[1]}`;
};
