import axios from "axios";

import * as actionTypes from "../actionTypes";
import { errorManageHandler, updateLoading, updateMessage } from "./theme";

import { API_BASE_URL, LS_AUTH_TOKEN_KEY } from "../../../utils/constants";
import { checkRoute } from "../../../utils/security";

export const updateToken = (token, keepToken = false) => {
    if (keepToken) {
        localStorage.setItem(LS_AUTH_TOKEN_KEY, token);
    }

    return {
        type: actionTypes.UPDATE_TOKEN,
        token,
    };
};

export const updateUserData = (userData) => {
    return {
        type: actionTypes.UPDATE_USER_DATA,
        userData,
    };
};

const updateBankAccounts = (bankAccounts) => {
    return {
        type: actionTypes.UPDATE_BANK_ACCOUNTS,
        bankAccounts,
    };
};

const updateBitcoinAccounts = (bitcoinAccounts) => {
    return {
        type: actionTypes.UPDATE_BITCOIN_ACCOUNTS,
        bitcoinAccounts,
    };
};

const updateLightningAddresses = (lightningAddresses) => {
    return {
        type: actionTypes.UPDATE_LIGHTNING_ADDRESSES,
        lightningAddresses,
    };
};

export const fetchUserData = (navigate, pathname) => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        if (!token) {
            dispatch(logout());
        } else {
            axios
                .get(`${API_BASE_URL}/auth/user`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    const userData = res.data;
                    dispatch(updateUserData(userData));
                    const { role } = userData;
                    if (!checkRoute(role, pathname)) {
                        navigate("/");
                    }
                })
                .catch((e) => {
                    dispatch(errorManageHandler(e));
                });
        }
    };
};

export const editUserData = (data, callback) => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        dispatch(updateLoading(true));
        axios
            .put(`${API_BASE_URL}/auth/user`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                dispatch(updateUserData(res.data));
                if (callback) callback();
                dispatch(updateLoading(false));
                dispatch(updateMessage("success", "Update profile succeeded!"));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const fetchBankAccounts = () => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        axios
            .get(`${API_BASE_URL}/auth/bank-accounts`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                dispatch(updateBankAccounts(res.data));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const createBankAccount = (data, callback) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        const token = getState().auth.token;
        axios
            .post(`${API_BASE_URL}/auth/bank-accounts`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const { bankAccounts } = getState().auth;
                if (bankAccounts) {
                    dispatch(updateBankAccounts([res.data, ...bankAccounts]));
                }
                callback();
                dispatch(updateLoading(false));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const updateBankAccount = (id, data, callback) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        const token = getState().auth.token;
        axios
            .put(`${API_BASE_URL}/auth/bank-account/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const { bankAccounts } = getState().auth;
                if (bankAccounts) {
                    const newBankAccounts = bankAccounts.map((b) => {
                        if (b.id === id) return res.data;
                        else return b;
                    });
                    dispatch(updateBankAccounts(newBankAccounts));
                }
                callback();
                dispatch(updateLoading(false));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const deleteBankAccount = (id) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        const token = getState().auth.token;
        axios
            .delete(`${API_BASE_URL}/auth/bank-account/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                const { bankAccounts } = getState().auth;
                if (bankAccounts) {
                    const newBankAccounts = bankAccounts.filter((b) => b.id !== id);
                    dispatch(updateBankAccounts(newBankAccounts));
                }
                dispatch(updateLoading(false));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const fetchBitcoinAccounts = () => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        axios
            .get(`${API_BASE_URL}/auth/bitcoin-accounts`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                dispatch(updateBitcoinAccounts(res.data));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const createBitcoinAccount = (data, callback) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        const token = getState().auth.token;
        axios
            .post(`${API_BASE_URL}/auth/bitcoin-accounts`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const { bitcoinAccounts } = getState().auth;
                if (bitcoinAccounts) {
                    dispatch(updateBitcoinAccounts([res.data, ...bitcoinAccounts]));
                }
                callback();
                dispatch(updateLoading(false));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const updateBitcoinAccount = (id, data, callback) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        const token = getState().auth.token;
        axios
            .put(`${API_BASE_URL}/auth/bitcoin-account/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const { bitcoinAccounts } = getState().auth;
                if (bitcoinAccounts) {
                    const newBitcoinAccounts = bitcoinAccounts.map((b) => {
                        if (b.id === id) return res.data;
                        else return b;
                    });
                    dispatch(updateBitcoinAccounts(newBitcoinAccounts));
                }
                callback();
                dispatch(updateLoading(false));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const deleteBitcoinAccount = (id) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        const token = getState().auth.token;
        axios
            .delete(`${API_BASE_URL}/auth/bitcoin-account/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                const { bitcoinAccounts } = getState().auth;
                if (bitcoinAccounts) {
                    const newBitcoinAccounts = bitcoinAccounts.filter((b) => b.id !== id);
                    dispatch(updateBitcoinAccounts(newBitcoinAccounts));
                }
                dispatch(updateLoading(false));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const fetchLightningAddresses = () => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        axios
            .get(`${API_BASE_URL}/auth/lightning-addresses`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                dispatch(updateLightningAddresses(res.data));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const createLightningAddress = (data, callback) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        const token = getState().auth.token;
        axios
            .post(`${API_BASE_URL}/auth/lightning-addresses`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const { lightningAddresses } = getState().auth;
                if (lightningAddresses) {
                    dispatch(updateLightningAddresses([res.data, ...lightningAddresses]));
                }
                callback();
                dispatch(updateLoading(false));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const updateLightningAddress = (id, data, callback) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        const token = getState().auth.token;
        axios
            .put(`${API_BASE_URL}/auth/lightning-address/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                const { lightningAddresses } = getState().auth;
                if (lightningAddresses) {
                    const newLightningAddresses = lightningAddresses.map((b) => {
                        if (b.id === id) return res.data;
                        else return b;
                    });
                    dispatch(updateLightningAddresses(newLightningAddresses));
                }
                callback();
                dispatch(updateLoading(false));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const deleteLightningAddress = (id) => {
    return (dispatch, getState) => {
        dispatch(updateLoading(true));
        const token = getState().auth.token;
        axios
            .delete(`${API_BASE_URL}/auth/lightning-address/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                const { lightningAddresses } = getState().auth;
                if (lightningAddresses) {
                    const newLightningAddresses = lightningAddresses.filter((b) => b.id !== id);
                    dispatch(updateLightningAddresses(newLightningAddresses));
                }
                dispatch(updateLoading(false));
            })
            .catch((e) => {
                dispatch(errorManageHandler(e));
            });
    };
};

export const logout = () => {
    return async () => {
        localStorage.removeItem(LS_AUTH_TOKEN_KEY);
        window.open("/login", "_parent");
    };
};
