/* eslint-disable max-len */
export const TRANSLATIONS_ES = {
    // COMMON
    fieldRequired: "Este campo es obligatorio",
    fieldInvalid: "Este campo no es válido",
    fieldMaxLimit: "Se requieren menos de {{limit}} caracteres",
    fieldMaxValueLimit: "El valor debe ser inferior a {{limit}}",
    fieldMinValueLimit: "El valor debe ser mayor que {{limit}}",
    fieldEmailDuplicated: "Correo electrónico duplicado",
    fieldConfirmPasswordCorrectly: "Confirmar contraseña correctamente",

    success: "Éxito",
    warning: "Advertencia",
    error: "Error",

    noPermission: "¡No tienes permiso!",
    URLNotFound: "URL no encontrada",
    badRequest: "Solicitud incorrecta",
    apiError: "Error de API",

    // HEADER
    myWallet: "Mi billetera",
    myProfile: "Mi perfil",
    withdrawalAccounts: "Cuentas de retiro",
    signOut: "Desconectar",

    // AUTH LAYOUT
    allRightReserved: "Reservados todos los derechos.",

    // LOGIN
    email: "Correo electrónico",
    password: "Contraseña",
    keepMeSignedIn: "Mantenerme registrado",
    signIn: "Iniciar sesión",
    forgetPassword: "Contraseña olvidada",
    stillHaveNoAccount: "¿Aún no tienes cuenta?",
    signUp: "Inscribirse",
    getCodeFromYourEmail: "Obtenga el código de su correo electrónico",
    getCodeFromYourAuthenticatorApp: "Obtenga el código de su aplicación de autenticación",
    enterCodeFromYourEmail: "Introduce el código de tu correo electrónico",
    accountSuspendedError:
        "Esta cuenta está suspendida, comuníquese con el soporte técnico a <a href='mailto:{{email}}' target='_blank'>{{email}}</a>",
    notAuthenticated: "¡No estás autenticado, inicia sesión nuevamente!",

    // REGISTER
    confirmPassword: "Confirmar contraseña",
    fullname: "Nombre completo",
    alreadyHaveAnAccount: "¿Ya tienes una cuenta?",
    passwordRule: "Se requieren 8 caracteres y al menos 1 carácter especial (!@#$&*)",

    // FORGET PASSWORD
    code: "Código",
    sendCode: "Enviar código",
    resetPassword: "Restablecer la contraseña",
    resendCode: "Reenviar codigo",
    backToSignIn: "Volver a iniciar sesión",
    verificationCodeSentSuccessfully: "El código de verificación se envió con éxito",

    // VERIFY ACCOUNT
    verify: "Verificar",
    verify_email: "Verificar correo electrónico",
    verify_phone: "Verificar teléfono",
    verifyDescriptionEmail:
        "Para validar su cuenta, hemos enviado un código de verificación a <b class='color-blue'>{{email}}</b>",
    verifyDescriptionPhone:
        "Para validar su cuenta, hemos enviado un segundo código de verificación a <b class='color-blue'>+{{phone}}</b>",
    verifySucceeded: "Su cuenta ha sido verificada exitosamente.",

    // SIDER
    dashboard: "Panel",
    transactions: "Actas",
    withdrawals: "Retiros",
    shift: "Cambio",
    companies: "Compañías",
    users: "Usuarios",
    pos: "POS",

    // DASHBOARD
    total: "Total",
    wallet: "Billetera",
    incoming: "Entrante",
    withdrawal: "Retiro",
    consumption: "Consumo",
    monthly: "Mensual",
    daily: "A diario",

    // INVOICES
    invoices: "Facturas",
    newInvoice: "Nueva factura",
    expireAt: "Caducar a las",
    localCurrency: "Moneda local",
    amountReceived: "Cantidad recibida",
    paidAt: "Pagado en",
    copyInvoiceUrl: "Copiar URL de factura",
    refresh: "Refrescar",
    noInvoice: "Sin factura",
    payLightningInvoice: "Pagar factura relámpago",
    pay: "Pagar",
    createInvoice: "Crear factura",
    invoiceDetail: "Detalle de la factura",
    invoiceIsExpired: "La factura está vencida",
    invoiceIsPaid: "Se paga la factura",
    awaitingPayment: "En espera de pago",
    close: "Cerca",
    copy: "Copiar",

    // TRANSACTIONS
    searchByDescription: "Buscar por descripción",
    selectPOSDevice: "Seleccionar dispositivo POS",
    all: "Todo",
    startDate: "Fecha de inicio",
    finishDate: "Fecha de finalización",
    date: "Fecha",
    company: "Compañía",
    device: "Dispositivo",
    description: "Descripción",
    amount: "Cantidad",
    tip: "Consejo",
    currency: "Divisa",
    commission: "Comisión",
    noTransaction: "Sin transacción",

    // WITHDRAWALS
    withdrawalToBitcoin: "Retiro a Bitcoin",
    withdrawalToLightningAddress: "Retiro a la dirección Lightning",
    withdrawalWithLightningInvoice: "Retiro con factura Lightning",
    lightningAddress: "Dirección de Lightning",
    selectLightningAddress: "Seleccione la dirección Lightning",
    withdrawalAmountLimitTip:
        "Te recordamos que por tu seguridad, el primer retiro en una cuenta nueva no puede exceder los {{firstAmount}} SAT. El segundo retiro en esta nueva cuenta podrá realizarse después de 24 horas, y por un monto máximo diario de {{dailyAmount}} SAT.",
    lightningWithdrawalInvoiceTip:
        "Esta solicitud de retiro será revisada y aprobada por el administrador, tomará algún tiempo, así que establezca suficiente vencimiento de la factura cuando cree una factura.",
    request: "Pedido",
    toBankAccount: "A la cuenta bancaria",
    toBitcoinAccount: "A la cuenta bitcoin",
    requestWithdrawal: "Solicitud de retirada",
    account: "Cuenta",
    status: "Estado",
    manager: "Gerente",
    bank: "Banco",
    bitcoin: "Bitcoin",
    noWithdrawal: "Sin retiro",
    requestWithdrawalToBank: "Solicitar retiro al banco",
    bankAccount: "Cuenta bancaria",
    selectBankAccount: "Seleccionar cuenta bancaria",
    shortDescriptionAboutWithdrawal: "Breve descripción sobre el retiro",
    cancel: "Cancelar",
    create: "Crear",
    requestWithdrawalToBitcoin: "Solicitar retiro a bitcoin",
    bitcoinAccount: "Cuenta bitcoin",
    selectBitcoinAccount: "Seleccionar cuenta bitcoin",
    balanceNotEnoughError: "El saldo de su billetera no es suficiente para completar el pago",
    withdrawalToLightningAccount: "Retiro a cuenta rayo",
    invoiceUrl: "URL de la factura",
    expiry: "Expiración",
    seconds: "Segundos",
    proceedWithdrawal: "Proceder retiro",
    withdrawalStatus_initiated: "Iniciado",
    withdrawalStatus_in_review: "En revisión",
    withdrawalStatus_in_process: "En proceso",
    withdrawalStatus_finished: "Finalizado",
    withdrawalStatus_rejected: "Rechazado",
    withdrawalStatusDescription_initiated:
        "Se crea la solicitud de retiro, en espera de la revisión por parte del gerente",
    withdrawalStatusDescription_in_review: "La solicitud de retiro está siendo revisada por el gerente",
    withdrawalStatusDescription_in_process: "La solicitud de retiro está en proceso",
    withdrawalStatusDescription_finished: "El proceso de retiro ha finalizado",
    withdrawalStatusDescription_rejected: "La solicitud de retiro es rechazada",

    // SHIFT
    name: "Nombre",
    startedAt: "Empezó a las",
    finishedAt: "Terminado en",
    deviceParingCode: "Código de emparejamiento del dispositivo",
    noShift: "Sin turno",

    // COMPANIES
    confirmDeleteCompany: "¿Estás seguro de eliminar esta empresa?",
    delete: "Borrar",
    companyName: "Nombre de empresa",
    createdAt: "Creado en",
    edit: "Editar",
    noCompanies: "Sin empresas",
    updateCompany: "Actualizar empresa",
    createCompany: "Crear empresa",
    saveChanges: "Guardar cambios",

    // USERS
    confirmDeleteUser: "¿Estás seguro de eliminar a este usuario?",
    noUsers: "Sin usuarios",
    updateUser: "Actualizar usuario",
    createUser: "Crear usuario",
    noCompanyAssigned: "Ninguna empresa asignada",
    reset: "Reiniciar",
    suspended: "Suspendido",
    yes: "Sí",
    assignedToNumberOfCompanies: "Asignado a {{number}} empresas",
    selectCompanies: "Seleccionar empresas",

    // DEVICES
    confirmDeleteDevice: "¿Estás seguro de eliminar este dispositivo?",
    POSDevices: "Dispositivos POS",
    paringCode: "Código de emparejamiento",
    user: "Usuario",
    printPurchase: "Imprimir compra",
    receiveTip: "Recibir propina",
    noPOSDevices: "Sin dispositivos POS",
    updateDevice: "Actualizar dispositivo",
    createDevice: "Crear dispositivo",
    noUserAssigned: "Ningún usuario asignado",
    marketType: "Tipo de mercado",

    // PROFILE
    generalInformation: "Información general",
    birthday: "Cumpleaños",
    phone: "Teléfono",
    country: "País",
    avatar: "Imagen de perfil",
    selectFile: "Seleccione Archivo",
    save: "Guardar",
    security: "Seguridad",
    "2fa": "Autenticación de dos factores",
    "2faDescription":
        "La autenticación de dos factores (2FA) es un método de seguridad utilizado para proteger su cuenta www.rodpay.com. El principal objetivo de la autenticación de dos factores es agregar una capa adicional de seguridad más allá de la contraseña tradicional. Al utilizar el correo electrónico como segundo factor de autenticación, incluso si alguien logra obtener su contraseña, no podrá acceder a su cuenta sin tener acceso a su correo electrónico para obtener el código.",
    allow2fa: "Activar la autenticación de dos factores (2FA)",
    setup2FASuccess: "La configuración de la autenticación de dos factores (2FA) se realizó correctamente",
    confirmDisable2fa: "¿Estás seguro de deshabilitar la autenticación de dos factores (2FA)?",
    confirm: "Confirmar",
    disable2fa: "Deshabilitar la autenticación de dos factores (2FA)",
    inputCode: "Codigo de entrada",
    "2faSetupGuide":
        "Escanee el siguiente código QR utilizando su aplicación de autenticación, como Google Authenticator, e ingrese el código.",
    customerInformation: "Información al cliente",
    nationalID: "Identificación nacional",
    companyDUNS: "Empresa DUNS",
    selectFileByClickOrDragFileHereToUpload:
        "Seleccione el archivo haciendo clic o arrastrando el archivo aquí para cargarlo",
    upload: "Subir",
    nationalIDNeededDescription: "Para retirar más de 1.000.000 de SAT, debes verificar tu DNI",
    companyDUNSNeededDescription: "Para retirar más de 10.000.000 SAT, debes verificar el DUNS de tu empresa",
    nationalIDIsUploaded: "Se carga el DNI",
    companyDUNSIsUploaded: "Se carga la empresa DUNS",
    inReview: "En revisión",
    approved: "Aprobado",
    rejected: "Rechazado",

    // INTEGRATION
    integration: "Integración",
    apiKey: "Clave API",
    apiKeyDescription:
        "Para integrar RodPay en su propia tienda WooCommerce, debe generar una clave API y configurar la pasarela de pago usándola.",
    generateApiKey: "Generar clave API",
    apiKeyGeneratedTip: "Esta clave API es visible solo una vez; guárdela en un lugar seguro.",
    copyToClipboard: "Copiar al portapapeles",
    apiKeySaved: "Clave API guardada",
    copiedToClipboard: "Clave API copiada al portapapeles",
    apiKeySavedTip: "Ya guardaste tu clave API, puedes generar una nueva o revocarla.",
    generateNewApiKey: "Generar nueva clave API",
    revokeApiKey: "Revocar clave API",
    confirmRefreshApiKey: "¿Estás seguro de actualizar la clave de API?",
    confirmRevokeApiKey: "¿Estás seguro de revocar la clave API?",
    revoke: "Revocar",

    // USER LOG
    actionLogs: "Registros de acción",
    action: "Acción",
    model: "Modelo",
    detail: "Detalle",
    user_action_create: "Crear",
    user_action_update: "Actualizar",
    user_action_delete: "Borrar",
    user_action_soft_delete: "Borrar",
    user_action_restore: "Restaurar",
    user_action_reset_password: "Restablecer la contraseña",
    user_action_tfa_enable: "Habilitar 2FA",
    user_action_tfa_disable: "Deshabilitar 2FA",
    user_action_update_profile: "Actualización del perfil",
    user_action_invoice_paid: "Factura pagada",
    user_action_invoice_expired: "Factura vencida",
    user_action_withdrawal_in_review: "Revisar el retiro",
    user_action_withdrawal_in_process: "Proceder retiro",
    user_action_withdrawal_finished: "Terminar el retiro",
    user_action_withdrawal_rejected: "Rechazar retiro",
    user_action_api_key_generated: "Clave API generada",
    user_action_api_key_revoked: "Clave API revocada",

    // WITHDRAWAL ACCOUNTS
    bankAccounts: "Cuentas bancarias",
    bitcoinAccounts: "Cuentas bitcoin",
    confirmDeleteBankAccount: "¿Estás seguro de eliminar esta cuenta bancaria?",
    noBankAccountAdded: "No se agregó ninguna cuenta bancaria",
    confirmDeleteBitcoinAccount: "¿Estás seguro de eliminar esta cuenta de bitcoin?",
    noBitcoinAccountAdded: "No se agregó ninguna cuenta de bitcoin",
    updateBankAccountInfo: "Actualizar la información de la cuenta bancaria",
    addNewBankAccount: "Agregar nueva cuenta bancaria",
    nickname: "Apodo",
    bankName: "Nombre del banco",
    swiftCode: "Código SWIFT",
    accountNumber: "Número de cuenta",
    accountName: "Nombre de la cuenta",
    address: "Dirección",
    zipCode: "Código postal",
    updateBitcoinAccountInfo: "Actualizar la información de la cuenta de bitcoin",
    addNewBitcoinAccount: "Agregar nueva cuenta bitcoin",
    lightningAddresses: "Direcciones de Lightning",
    addNewLightningAddress: "Agregar nueva dirección Lightning",
    confirmDeleteLightningAddress: "¿Estás seguro de eliminar esta dirección de Lightning?",
    noLightningAddressAdded: "No se agregó ninguna dirección Lightning",

    // WITHDRAWAL
    firstWithdrawalAmountLimitExceeded:
        "Se superó el límite de monto de retiro. El monto del primer retiro debe ser inferior a 5.000 SAT.",
    secondWithdrawalTimeLimitExceeded:
        "Se superó el límite de tiempo de retiro. El segundo retiro se podrá realizar después de 24 horas del primer retiro.",
    oneDayWithdrawalAmountLimitExceeded:
        "Se superó el límite de monto de retiro. El monto del retiro diario debe ser inferior a 1.000.000 de SAT.",
    withdrawalKYCNationalIDRequired:
        "El monto total de su retiro excede el límite (1.000.000 SAT), verifique su identificación nacional para continuar.",
    withdrawalKYCCompanyDUNSRequired:
        "El monto total de su retiro excede el límite (10.000.000 SAT), verifique el DUNS de su empresa para continuar.",
    lightningInvoice: "Factura de Lightning",

    // PAYMENT PAGE
    waitingForPayment: "A la espera del pago...",
    invoiceQRCodeTip: "Escanee el código QR para pagar la factura o haga clic para copiar la URL de la factura.",
    invoiceURLCopied: "URL de la factura copiada al portapapeles.",
    invoiceExpired: "¡La factura expiró!",
    paymentSucceeded: "¡Pago exitoso!",
    redirecting: "Redirigiendo...",
};
