import * as actionTypes from "../actionTypes";

import { LS_AUTH_TOKEN_KEY } from "../../../utils/constants";
import { updateState } from "../utils";

const initialState = {
    token: localStorage.getItem(LS_AUTH_TOKEN_KEY) || null,
    userData: null,
    bankAccounts: null,
    bitcoinAccounts: null,
    lightningAddresses: null,
};

const updateToken = (state, action) => {
    return updateState(state, {
        token: action.token,
    });
};

const updateUserData = (state, action) => {
    return updateState(state, {
        userData: action.userData,
    });
};

const updateBankAccounts = (state, action) => {
    return updateState(state, {
        bankAccounts: action.bankAccounts,
    });
};

const updateBitcoinAccounts = (state, action) => {
    return updateState(state, {
        bitcoinAccounts: action.bitcoinAccounts,
    });
};

const updateLightningAddresses = (state, action) => {
    return updateState(state, {
        lightningAddresses: action.lightningAddresses,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TOKEN:
            return updateToken(state, action);
        case actionTypes.UPDATE_USER_DATA:
            return updateUserData(state, action);
        case actionTypes.UPDATE_BANK_ACCOUNTS:
            return updateBankAccounts(state, action);
        case actionTypes.UPDATE_BITCOIN_ACCOUNTS:
            return updateBitcoinAccounts(state, action);
        case actionTypes.UPDATE_LIGHTNING_ADDRESSES:
            return updateLightningAddresses(state, action);
        default:
            return state;
    }
};

export default reducer;
