import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { rgba } from "polished";

import { Card } from "antd";

const props = {
    children: PropTypes.any,
    className: PropTypes.string,

    border: PropTypes.string,
    shadow: PropTypes.bool,
    padding: PropTypes.number,
};

const BHCard = ({ children, className, border, shadow, padding = 24 }) => {

    return (
        <Wrapper
            className={className}
            border={border}
            shadow={(shadow || true).toString()}
            padding={padding}
        >
            {children}
        </Wrapper>
    );
};

const Wrapper = styled(Card)`
    border: ${(props) => props.border ? `1px solid ${props.border}` : "none"};
    box-shadow: ${(props) => props.shadow === "true" ? `0 5px 8px ${rgba(props.theme.colorDarkGray, .5)}` : "none"};

    overflow: visible;

    background-color: ${(props) => props.theme.colorWhite};

    .ant-card-body {
        padding: ${(props) => props.padding !== undefined ? props.padding : "24"}px;

        &::before, &::after {
            display: none;
        }
    }
`;

BHCard.propTypes = props;

export default BHCard;