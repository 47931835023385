import React, { useEffect } from "react";
import Loadable from "react-loadable";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

import en from "antd/locale/en_US";
import es from "antd/locale/es_ES";
import pt from "antd/locale/pt_BR";
import fr from "antd/locale/fr_FR";
import it from "antd/locale/it_IT";
import ru from "antd/locale/ru_RU";
import th from "antd/locale/th_TH";
import vi from "antd/locale/vi_VN";
import fi from "antd/locale/fi_FI";
import zh from "antd/locale/zh_CN";
import { ConfigProvider, notification } from "antd";
import { Loader } from "./components/base";

import { antdThemes, styledThemes } from "./utils/themes";
import { updateMessage } from "./services/store/actions/theme";

const locales = {
    en,
    es,
    pt,
    fr,
    it,
    ru,
    th,
    vi,
    fi,
    zh,
};

const loading = () => (
    <>
        <Loader />
    </>
);

const Login = Loadable({
    loader: () => import("./pages/Auth/Login/Login"),
    loading,
});

const Register = Loadable({
    loader: () => import("./pages/Auth/Register/Register"),
    loading,
});

const Verify = Loadable({
    loader: () => import("./pages/Auth/Verify/Verify"),
    loading,
});

const ForgetPassword = Loadable({
    loader: () => import("./pages/Auth/ForgetPassword/ForgetPassword"),
    loading,
});

const PosLocationMap = Loadable({
    loader: () => import("./pages/External/PosLocationMap/PosLocationMap"),
    loading,
});

const InvoicePay = Loadable({
    loader: () => import("./pages/External/InvoicePay/InvoicePay"),
    loading,
});

const MainLayout = Loadable({
    loader: () => import("./layouts/MainLayout/MainLayout"),
    loading,
});

function App() {
    const dispatch = useDispatch();
    const { t, i18n: i18nHook } = useTranslation();

    const { message, theme } = useSelector((state) => state.theme);

    useEffect(() => {
        if (message.content !== null) {
            notification[message.type]({
                message: t(message.type).toUpperCase(),
                description: message.content,
                onClose: () => dispatch(updateMessage(null, null)),
            });
        }
    }, [message.content]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ConfigProvider theme={antdThemes[theme]} locale={locales[i18nHook.language] || es}>
            <ThemeProvider theme={styledThemes[theme]}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/verify/:action" element={<Verify />} />
                        <Route path="/forget-password" element={<ForgetPassword />} />
                        <Route path="/locations" element={<PosLocationMap />} />
                        <Route path="/pay/:invoiceId" element={<InvoicePay />} />
                        <Route path="/*" element={<MainLayout />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </ConfigProvider>
    );
}

export default App;
