/* eslint-disable max-len */
export const TRANSLATIONS_FI = {
    // COMMON
    fieldRequired: "Kinakailangan ang field na ito",
    fieldInvalid: "Di-wasto ang field na ito",
    fieldMaxLimit: "Mas mababa sa {{limit}} na mga character ang kinakailangan",
    fieldMaxValueLimit: "Ang halaga ay dapat na mas mababa sa {{limit}}",
    fieldMinValueLimit: "Ang halaga ay dapat na mas malaki kaysa sa {{limit}}",
    fieldEmailDuplicated: "Nadoble ang email",
    fieldConfirmPasswordCorrectly: "Kumpirmahin nang tama ang password",

    success: "Tagumpay",
    warning: "Babala",
    error: "Error",

    noPermission: "Wala kang pahintulot!",
    URLNotFound: "Hindi nahanap ang URL",
    badRequest: "Masamang kahilingan",
    apiError: "Error sa API",

    // HEADER
    myWallet: "Yung wallet ko",
    myProfile: "Aking profile",
    withdrawalAccounts: "Mga withdrawal account",
    signOut: "Mag-sign Out",

    // AUTH LAYOUT
    allRightReserved: "Lahat ng karapatan ay nakalaan.",

    // LOGIN
    email: "Email",
    password: "Password",
    keepMeSignedIn: "Panatilihin akong naka-sign in",
    signIn: "Mag-sign In",
    forgetPassword: "Kalimutan ang password",
    stillHaveNoAccount: "Wala pa ring account?",
    signUp: "Mag-sign Up",
    getCodeFromYourEmail: "Kumuha ng code mula sa iyong email",
    getCodeFromYourAuthenticatorApp: "Kumuha ng code mula sa iyong authenticator app",
    enterCodeFromYourEmail: "Maglagay ng code mula sa iyong email",
    accountSuspendedError:
        "Nasuspinde ang account na ito, mangyaring makipag-ugnayan sa suporta sa <a href='mailto:{{email}}' target='_blank'>{{email}}</a>",
    notAuthenticated: "Hindi ka napatotohanan, mangyaring mag-login muli!",

    // REGISTER
    confirmPassword: "Kumpirmahin ang password",
    fullname: "Buong pangalan",
    alreadyHaveAnAccount: "Mayroon nang account?",
    passwordRule: "8 character at hindi bababa sa 1 espesyal na character(!@#$&*) ang kinakailangan",

    // FORGET PASSWORD
    code: "Code",
    sendCode: "Magpadala ng code",
    resetPassword: "I-reset ang password",
    resendCode: "Muling ipadala ang code",
    backToSignIn: "Bumalik sa Mag-sign In",
    verificationCodeSentSuccessfully: "Matagumpay na naipadala ang verification code",

    // VERIFY ACCOUNT
    verify: "I-verify",
    verify_email: "I-verify ang email",
    verify_phone: "I-verify ang telepono",
    verifyDescriptionEmail:
        "Upang mapatunayan ang iyong account, nagpadala kami ng verification code sa <b class='color-blue'>{{email}}</b>",
    verifyDescriptionPhone:
        "Upang mapatunayan ang iyong account, nagpadala kami ng pangalawang verification code sa <b class='color-blue'>+{{phone}}</b>",
    verifySucceeded: "Ang iyong account ay matagumpay na na-verify.",

    // SIDER
    dashboard: "Dashboard",
    transactions: "Mga transaksyon",
    withdrawals: "Mga withdrawal",
    shift: "Paglipat",
    companies: "Mga kumpanya",
    users: "Mga gumagamit",
    pos: "POS",

    // DASHBOARD
    total: "Kabuuan",
    wallet: "Wallet",
    incoming: "Papasok",
    withdrawal: "Pag-withdraw",
    consumption: "Pagkonsumo",
    monthly: "Buwan-buwan",
    daily: "Araw-araw",

    // INVOICES
    invoices: "Mga invoice",
    newInvoice: "Bagong invoice",
    expireAt: "Mag-e-expire sa",
    localCurrency: "Lokal na pera",
    amountReceived: "Halagang natanggap",
    paidAt: "Binayaran sa",
    copyInvoiceUrl: "Kopyahin ang URL ng invoice",
    refresh: "Refresh",
    noInvoice: "Walang invoice",
    payLightningInvoice: "Magbayad ng Lightning invoice",
    pay: "Magbayad",
    createInvoice: "Gumawa ng invoice",
    invoiceDetail: "Detalye ng invoice",
    invoiceIsExpired: "Nag-expire na ang invoice",
    invoiceIsPaid: "Binabayaran ang invoice",
    awaitingPayment: "Naghihintay ng bayad",
    close: "Isara",
    copy: "Kopya",

    // TRANSACTIONS
    searchByDescription: "Maghanap ayon sa paglalarawan",
    selectPOSDevice: "Piliin ang POS device",
    all: "Lahat",
    startDate: "Petsa ng pagsisimula",
    finishDate: "Katapusan",
    date: "Petsa",
    company: "Kumpanya",
    device: "Device",
    description: "Paglalarawan",
    amount: "Halaga",
    tip: "Tip",
    currency: "Pera",
    commission: "Komisyon",
    noTransaction: "Walang transaksyon",

    // WITHDRAWALS
    withdrawalToBitcoin: "Pag-withdraw sa Bitcoin",
    withdrawalToLightningAddress: "Pag-withdraw sa Lightning Address",
    withdrawalWithLightningInvoice: "Pag-withdraw gamit ang Lightning na invoice",
    lightningAddress: "Lightning Address",
    selectLightningAddress: "Piliin ang Lightning Address",
    withdrawalAmountLimitTip:
        "Ipinapaalala namin sa iyo na para sa iyong seguridad, ang unang pag-withdraw sa isang bagong account ay hindi maaaring lumampas sa {{firstAmount}} SATs. Ang pangalawang pag-withdraw sa bagong account na ito ay maaaring gawin pagkatapos ng 24 na oras, at para sa maximum na pang-araw-araw na halaga ng {{dailyAmount}} SATs.",
    lightningWithdrawalInvoiceTip:
        "Ang kahilingan sa pag-withdraw na ito ay susuriin at aaprubahan ng admin, ito ay magtatagal, kaya mangyaring magtakda ng sapat na pag-expire ng invoice kapag gumawa ka ng isang invoice.",
    request: "Hiling",
    toBankAccount: "Sa bank account",
    toBitcoinAccount: "Sa bitcoin account",
    requestWithdrawal: "Humiling ng withdrawal",
    account: "Account",
    status: "Katayuan",
    manager: "Manager",
    bank: "Bangko",
    bitcoin: "Bitcoin",
    noWithdrawal: "Walang withdrawal",
    requestWithdrawalToBank: "Humiling ng pag-withdraw sa bangko",
    bankAccount: "Bank account",
    selectBankAccount: "Pumili ng bank account",
    shortDescriptionAboutWithdrawal: "Maikling paglalarawan tungkol sa withdrawal",
    cancel: "Kanselahin",
    create: "Lumikha",
    requestWithdrawalToBitcoin: "Humiling ng pag-withdraw sa bitcoin",
    bitcoinAccount: "Bitcoin account",
    selectBitcoinAccount: "Pumili ng bitcoin account",
    balanceNotEnoughError: "Ang iyong balanse sa wallet ay hindi sapat upang makumpleto ang pagbabayad",
    withdrawalToLightningAccount: "Pag-withdraw sa lightning account",
    invoiceUrl: "URL ng invoice",
    expiry: "Expiry",
    seconds: "Mga segundo",
    proceedWithdrawal: "Ipagpatuloy ang pag-withdraw",
    withdrawalStatus_initiated: "Sinimulan",
    withdrawalStatus_in_review: "Sa pagsusuri",
    withdrawalStatus_in_process: "Pinoproseso",
    withdrawalStatus_finished: "Tapos na",
    withdrawalStatus_rejected: "Tinanggihan",
    withdrawalStatusDescription_initiated:
        "Ang kahilingan sa pag-withdraw ay ginawa, naghihintay para sa pagsusuri ng manager",
    withdrawalStatusDescription_in_review: "Ang kahilingan sa withdrawal ay nasa ilalim ng pagsusuri ng manager",
    withdrawalStatusDescription_in_process: "Ang kahilingan sa withdrawal ay nasa proseso",
    withdrawalStatusDescription_finished: "Tapos na ang proseso ng withdrawal",
    withdrawalStatusDescription_rejected: "Tinanggihan ang kahilingan sa pag-withdraw",

    // SHIFT
    name: "Pangalan",
    startedAt: "Nagsimula sa",
    finishedAt: "Natapos sa",
    deviceParingCode: "Code ng pagpapares ng device",
    noShift: "Walang shift",

    // COMPANIES
    confirmDeleteCompany: "Sigurado ka bang tatanggalin ang kumpanyang ito?",
    delete: "Tanggalin",
    companyName: "Pangalan ng Kumpanya",
    createdAt: "Nilikha sa",
    edit: "I-edit",
    noCompanies: "Walang mga kumpanya",
    updateCompany: "I-update ang kumpanya",
    createCompany: "Lumikha ng kumpanya",
    saveChanges: "I-save ang mga pagbabago",

    // USERS
    confirmDeleteUser: "Sigurado ka bang tatanggalin ang user na ito?",
    noUsers: "Walang gumagamit",
    updateUser: "I-update ang user",
    createUser: "Lumikha ng user",
    noCompanyAssigned: "Walang kumpanyang nakatalaga",
    reset: "I-reset",
    suspended: "Nasuspinde",
    yes: "Oo",
    assignedToNumberOfCompanies: "Nakatalaga sa {{number}} kumpanya",
    selectCompanies: "Pumili ng mga kumpanya",

    // DEVICES
    confirmDeleteDevice: "Sigurado ka bang tatanggalin ang device na ito?",
    POSDevices: "Mga POS Device",
    paringCode: "Code ng pagpapares",
    user: "Gumagamit",
    printPurchase: "Pagbili ng print",
    receiveTip: "Tumanggap ng tip",
    noPOSDevices: "Walang POS device",
    updateDevice: "I-update ang device",
    createDevice: "Gumawa ng device",
    noUserAssigned: "Walang nakatalagang user",
    marketType: "Uri ng merkado",

    // PROFILE
    generalInformation: "Pangkalahatang Impormasyon",
    birthday: "Birthday",
    phone: "Telepono",
    country: "Bansa",
    avatar: "Larawan sa profile",
    selectFile: "Pumili ng file",
    save: "I-save",
    security: "Seguridad",
    "2fa": "Dalawang kadahilanan na pagpapatunay",
    "2faDescription":
        "Ang two-factor authentication (2FA) ay isang paraan ng seguridad na ginagamit upang protektahan ang iyong www.rodpay.com account. Ang pangunahing layunin ng two-factor authentication ay magdagdag ng karagdagang layer ng seguridad na lampas sa tradisyonal na password. Sa pamamagitan ng paggamit ng email bilang pangalawang salik ng pagpapatunay, kahit na may nakakakuha ng iyong password, hindi nila maa-access ang iyong account nang walang access sa iyong email upang makuha ang code.",
    allow2fa: "I-activate ang Two-factor authentication (2FA)",
    setup2FASuccess: "Nagtagumpay ang pagtatakda ng Two-factor authentication (2FA).",
    confirmDisable2fa: "Sigurado ka bang idi-disable ang Two-factor authentication (2FA)?",
    confirm: "Kumpirmahin",
    disable2fa: "I-disable ang Two-factor authentication (2FA)",
    inputCode: "Input code",
    "2faSetupGuide":
        "Mangyaring i-scan sa ibaba ang QR code gamit ang iyong authenticator app gaya ng Google Authenticator, at input code.",
    customerInformation: "Impormasyon ng customer",
    nationalID: "Pambansang ID",
    companyDUNS: "Kumpanya DUNS",
    selectFileByClickOrDragFileHereToUpload:
        "Pumili ng file sa pamamagitan ng pag-click o pag-drag ng file dito para i-upload",
    upload: "Mag-upload",
    nationalIDNeededDescription:
        "Upang makapag-withdraw ng higit sa 1,000,000 SAT, kailangan mong i-verify ang iyong National ID",
    companyDUNSNeededDescription:
        "Upang mag-withdraw ng higit sa 10,000,000 SAT, kailangan mong i-verify ang iyong Kumpanya DUNS",
    nationalIDIsUploaded: "Na-upload ang National ID",
    companyDUNSIsUploaded: "Ang kumpanya DUNS ay na-upload",
    inReview: "Sa pagsusuri",
    approved: "Naaprubahan",
    rejected: "Tinanggihan",

    // INTEGRATION
    integration: "Pagsasama",
    apiKey: "API Key",
    apiKeyDescription:
        "Upang isama ang RodPay sa iyong sariling WooCommerce store, kailangan mong bumuo ng API key at i-configure ang mga setting ng gateway ng pagbabayad gamit ito.",
    generateApiKey: "Bumuo ng API Key",
    apiKeyGeneratedTip: "Isang beses lang makikita ang API Key na ito, mangyaring i-save ito sa isang secure na lugar.",
    copyToClipboard: "Kopyahin sa clipboard",
    apiKeySaved: "Na-save ang API Key",
    copiedToClipboard: "Nakopya ang API Key sa clipboard",
    apiKeySavedTip: "Nai-save mo na ang iyong API Key, maaari kang bumuo ng bago o bawiin ito.",
    generateNewApiKey: "Bumuo ng bagong API Key",
    revokeApiKey: "Bawiin ang API Key",
    confirmRefreshApiKey: "Sigurado ka bang ire-refresh ang API Key?",
    confirmRevokeApiKey: "Sigurado ka bang babawiin ang API Key?",
    revoke: "Bawiin",

    // USER LOG
    actionLogs: "Mga log ng aksyon",
    action: "Aksyon",
    model: "Modelo",
    detail: "Detalye",
    user_action_create: "Lumikha",
    user_action_update: "Update",
    user_action_delete: "Tanggalin",
    user_action_soft_delete: "Tanggalin",
    user_action_restore: "Ibalik",
    user_action_reset_password: "I-reset ang password",
    user_action_tfa_enable: "Paganahin ang 2FA",
    user_action_tfa_disable: "Huwag paganahin ang 2FA",
    user_action_update_profile: "I-update ang profile",
    user_action_invoice_paid: "Binayaran ang invoice",
    user_action_invoice_expired: "Nag-expire ang invoice",
    user_action_withdrawal_in_review: "Suriin ang withdrawal",
    user_action_withdrawal_in_process: "Ipagpatuloy ang pag-withdraw",
    user_action_withdrawal_finished: "Tapusin ang withdrawal",
    user_action_withdrawal_rejected: "Tanggihan ang pag-withdraw",
    user_action_api_key_generated: "Nabuo ang API Key",
    user_action_api_key_revoked: "Binawi ang API Key",

    // WITHDRAWAL ACCOUNTS
    bankAccounts: "Mga Bank Account",
    bitcoinAccounts: "Mga account sa bitcoin",
    confirmDeleteBankAccount: "Sigurado ka bang tatanggalin ang bank account na ito?",
    noBankAccountAdded: "Walang naidagdag na bank account",
    confirmDeleteBitcoinAccount: "Sigurado ka bang tatanggalin ang bitcoin account na ito?",
    noBitcoinAccountAdded: "Walang naidagdag na bitcoin account",
    updateBankAccountInfo: "I-update ang impormasyon ng bank account",
    addNewBankAccount: "Magdagdag ng bagong bank account",
    nickname: "Palayaw",
    bankName: "Pangalan ng bangko",
    swiftCode: "Swift code",
    accountNumber: "Account number",
    accountName: "Pangalan ng account",
    address: "Address",
    zipCode: "ZIP code",
    updateBitcoinAccountInfo: "I-update ang impormasyon ng bitcoin account",
    addNewBitcoinAccount: "Magdagdag ng bagong bitcoin account",
    lightningAddresses: "Lightning Mga Address",
    addNewLightningAddress: "Magdagdag ng bagong Lightning Address",
    confirmDeleteLightningAddress: "Sigurado ka bang tatanggalin ang Lightning Address na ito?",
    noLightningAddressAdded: "Walang idinagdag na Lightning Address",

    // WITHDRAWAL
    firstWithdrawalAmountLimitExceeded:
        "Lumampas sa limitasyon sa halaga ng withdrawal. Ang unang halaga ng withdrawal ay dapat na mas mababa sa 5,000 SATs.",
    secondWithdrawalTimeLimitExceeded:
        "Lumampas sa limitasyon sa oras ng pag-withdraw. Ang pangalawang pag-withdraw ay maaaring gawin pagkatapos ng 24 na oras mula sa unang pag-withdraw.",
    oneDayWithdrawalAmountLimitExceeded:
        "Lumampas sa limitasyon sa halaga ng withdrawal. Ang pang-araw-araw na halaga ng withdrawal ay dapat na mas mababa sa 1,000,000 SATs.",
    withdrawalKYCNationalIDRequired:
        "Ang iyong kabuuang halaga ng withdrawal ay lumampas sa limitasyon (1,000,000 SAT), paki-verify ang iyong National ID upang magpatuloy.",
    withdrawalKYCCompanyDUNSRequired:
        "Ang iyong kabuuang halaga ng withdrawal ay lumampas sa limitasyon (10,000,000 SATs), paki-verify ang iyong DUNS ng Kumpanya upang magpatuloy.",
    lightningInvoice: "Lightning invoice",

    // PAYMENT PAGE
    waitingForPayment: "Naghihintay ng mga bayad...",
    invoiceQRCodeTip: "I-scan ang QR code para bayaran ang invoice o i-click para kopyahin ang URL ng invoice.",
    invoiceURLCopied: "Nakopya ang URL ng invoice sa clipboard.",
    invoiceExpired: "Nag-expire na ang invoice!",
    paymentSucceeded: "Nagtagumpay ang pagbabayad!",
    redirecting: "Nire-redirect...",
};
